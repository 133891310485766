import React from 'react';
import '../../styles/GlobalStyle.css';
import './style.css';




export default function About(){
   return (
      <main className="">

      </main>
   )
}
